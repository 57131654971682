<template>
  <window-default ref="window" :resolve="find" :mock="mock" :props="props" v-slot="{data}"
                  @loaded="(obj) => model = obj" @save="save" :proccess-data="proccessData">
    <window-body class="wrapper-lg">
      <e-row mr>
        <e-col size="1">
          <erp-s-field
              label="ID:"
          >
            <erp-input v-model="model.id" size="2" readonly/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Banco:"
          >
            <select v-model="model.codigoBanco" id="bankAccountOption" name="bankAccountOption"
                    class="erp-input erp-if-size-"
                    required="required">
              <option value="">
                Selecione o banco
              </option>
              <option value="218"
              >
                Banco BS2
              </option>
              <option value="1"
              >
                Banco do Brasil
              </option>
              <option value="237"
              >
                Bradesco
              </option>
              <option value="104"
              >
                Caixa Econômica
              </option>
              <option value="341"
              >
                Itaú
              </option>
              <option value="33"
              >
                Santander
              </option>
              <option value="748"
              >
                Sicredi
              </option>
              <option value="85">
                Ailos
              </option>
              <option value="70">
                Banco de Brasília
              </option>
              <option value="4">
                Banco do Nordeste
              </option>
              <option value="77">
                Banco Inter
              </option>
              <option value="212">
                Banco Original
              </option>
              <option value="356">
                Banco Real
              </option>
              <option value="422">
                Banco Safra
              </option>
              <option value="756">
                Bancoob
              </option>
              <option value="21">
                Banestes
              </option>
              <option value="479">
                BankBoston
              </option>
              <option value="37">
                Banpara
              </option>
              <option value="41">
                Banrisul
              </option>
              <option value="291">
                BCN
              </option>
              <option value="336">
                C6
              </option>
              <option value="745">
                Citibank
              </option>
              <option value="403">
                Cora
              </option>
              <option value="89">
                Credisan
              </option>
              <option value="399">
                HSBC
              </option>
              <option value="389">
                Mercantil do Brasil
              </option>
              <option value="735">
                Neon
              </option>
              <option value="151">
                Nossa Caixa
              </option>
              <option value="260">
                Nubank
              </option>
              <option value="999">
                Sicoob
              </option>
              <option value="347">
                Sudameris
              </option>
              <option value="409">
                Unibanco
              </option>
              <option value="136">
                Unicred
              </option>
              <option value="-20">
                Outro Banco
              </option>
            </select>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Nome para a Conta:"
          >
            <erp-input v-model="model.nome" autofocus size="2"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <!--      <e-row mr>
              <e-col>
                <erp-s-field
                    label="Codigo/Número do Banco:"
                >
                  <erp-input v-model="model.codigoBanco" />
                </erp-s-field>
              </e-col>
            </e-row>-->

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Agência:"
          >
            <erp-input v-model="model.agencia"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Conta:"
          >
            <erp-input v-model="model.conta"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Favorecido:"
          >
            <erp-input v-model="model.favorecido"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="CPF/CNPJ:"
          >
            <erp-input v-model="model.documento"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Chave PIX:"
          >
            <erp-input v-model="model.chavePix" autofocus size="2"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Pessoa Titular:"
          >
            <global-pessoa-input v-model="model.pessoa" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Saldo inicial:"
          >
            <erp-input v-model="model.saldoInicial" v-money="money"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Data do saldo inicial:"
          >
            <datetime-input date-type="date" date-format="##/##/####"
                            v-model.lazy="model.dataSaldoInicial"
                            simpleBorder/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Ativo:"
          >
            <erp-checkbox v-model="model.active"/>
          </erp-s-field>
        </e-col>
      </e-row>
    </window-body>
  </window-default>
</template>

<script>
import {WindowBody, ErpSField, ErpInput, ErpCheckbox} from 'uloc-vue-plugin-erp'
import WindowDefault from '../../../../../reuse/window/Default'
import DatetimeInput from "@/reuse/input/Datetime"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
import {
  findBanco as find,
  newBanco as _new,
  updateBanco as update,
} from '../../../../../domain/cadastro/services'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import save from '../../../helpers/save'
import {datePtToEn} from "@/utils/date"
import GlobalPessoaInput from "components/pessoa/components/include/pessoa/PessoaInput"

let mock = {
  id: null,
  nome: null,
  codigoBanco: null,
  agencia: null,
  conta: null,
  favorecido: null,
  documento: null,
  active: true,
  saldoInicial: null,
  chavePix: null,
  dataSaldoInicial: null,
  pessoa: null
}

export default {
  name: 'Window',
  components: {GlobalPessoaInput, ECol, ERow, WindowDefault, WindowBody, ErpSField, ErpInput, ErpCheckbox, DatetimeInput},
  props: ['props'],
  directives: {money: VMoney},
  data() {
    return {
      model: JSON.parse(JSON.stringify(mock)),
      money: REAL_BRL
    }
  },
  mounted() {
  },
  computed: {
    find() {
      return find
    },
    mock() {
      return mock
    }
  },
  methods: {
    update: update,
    new: _new,
    save: save,
    beforeSave (originalData) {
      let data = JSON.parse(JSON.stringify(originalData))
      const valores = ['saldoInicial']
      valores.map(key => {
        data[key] = convertRealToMoney(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })
      if (data.dataSaldoInicial) {
        data.dataSaldoInicial = datePtToEn(data.dataSaldoInicial)
      }
      if (data.pessoa && data.pessoa.id) {
        data.pessoa = data.pessoa.id
      }
      return data
    },
    proccessData (_data) {
      let _mock = JSON.parse(JSON.stringify(this.mock))
      const data = Object.assign(_mock, _data)
      const datas = ['dataSaldoInicial']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })
      return data
    }
  }
}
</script>
